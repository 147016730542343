
import pattern from '~/mixins/pattern.js';
export default {
    data() {
        return {
            forceHide : false
        }
    },
    props : ['blok','enableArrows'],
    mixins: [pattern]
}
